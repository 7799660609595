import {Modal, ModalProps} from '../../../../ui/Modal';
import CloseModalButton from '../../../../components/CloseModalButton';
import {useI18n} from '../../../../hooks/use-i18n';
import {LoginButtonProps} from '../../types';
import CreatePasswordBottomDescription from './CreatePasswordBottomDescription';
import {FormEvent, useEffect, useState} from 'react';
import PasswordInput from '../PasswordInput';
import {usePassword} from '../../lib/use-password';
import {PasswordError} from '../PasswordError';
import {useToggle} from '../../../../hooks/use-toggle';
import {api} from '../../../../http/api';
import {LoginPostResponse} from '../../../../shared/login-response';
import {AxiosError} from 'axios';
import ClickIdStorage from '../../../../storages/click-id-storage';
import {di} from '../../../../dependency-injector';
import classNames from 'classnames';
import {ButtonWithLoader} from '../../../../ui/ButtonWithLoader';
import {usePresetRole} from '../../lib/usePresetRole';
import {useUserCountry} from '../../../../hooks/use-user-country';
import {isSharedError} from '../../../../shared/shared-error';
import {LoginError} from '../LoginError';

interface Props extends ModalProps, LoginButtonProps {
  email: string;
  setEmail: (email: string) => void;
}

const CreatePasswordModal = (props: Props) => {
  const {isOpen, hide, email, setEmail, onSuccessLogin} = props;
  const {t, language} = useI18n();
  const [loginError, setLoginError] = useState<string | null>(null);
  const userCountry = useUserCountry();
  const [revealed, toggleRevealed] = useToggle();
  const [isFetching, setIsFetching] = useState(false);
  const presetRole = usePresetRole();
  const {
    password,
    confirmPassword,
    setPassword,
    setConfirmPassword,
    invalidReasonKey,
    passwordErrored,
    confirmPasswordErrored,
  } = usePassword();

  useEffect(() => {
    if (!isOpen) {
      setPassword('');
      setConfirmPassword('');
      setEmail('');
    }
  }, [isOpen, setConfirmPassword, setPassword, setEmail]);

  // Disable create button if there are active errors or password is empty
  const disableCreateButton = passwordErrored || confirmPasswordErrored || !password.length;
  const utmParams = di.trafficAnalyticsService.getUTMParams();

  const onCreateAccountClick = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (disableCreateButton) {
      return;
    }

    try {
      setIsFetching(true);
      const response = await api.post<LoginPostResponse>('api/v1/register', {
        method: 'email',
        data: {
          email,
          password,
          language,
          userCountry,
          clickId: utmParams?.clickId ?? ClickIdStorage.get(),
          userType: presetRole,
        },
      });
      if (!response.data.success) {
        const _errorData = isSharedError(response.data.error)
          ? t(response.data.error.translation)
          : response.data.error;
        alert(_errorData);
        return;
      }
      onSuccessLogin?.(response.data.data);
      setPassword('');
      setConfirmPassword('');
    } catch (e) {
      if (isSharedError(e)) {
        console.log('1');
        setLoginError(t(e?.translation));
        return;
      } else if (e instanceof AxiosError) {
        console.log('2');
        setLoginError(e?.response?.data?.error ?? 'something-went-wrong-try-again-later');
        return;
      }
      console.log('3');
      setLoginError('something-went-wrong-try-again-later');
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      hide={hide}
      backdropClassName={'p-0 lg:p-20'}
      className={'w-full rounded px-20 py-44 lg:h-auto lg:w-500 lg:p-54'}
    >
      <CloseModalButton onClick={hide} />
      <div className="mb-24 text-center text-[2.6rem] font-bold sm:text-2xl">{t('create-password')}</div>
      <form className={'flex flex-col'} onSubmit={onCreateAccountClick}>
        {/* keep this hidden email input to help browser suggest user to save email/password */}
        <input type="email" className={'hidden'} autoComplete="email" value={email} readOnly={true} />
        <PasswordInput
          revealed={revealed}
          toggleRevealed={toggleRevealed}
          type={'new-password'}
          label={'password'}
          password={password}
          setPassword={setPassword}
          hasError={passwordErrored}
        />
        <PasswordInput
          revealed={revealed}
          toggleRevealed={toggleRevealed}
          type={'new-password'}
          label={'confirm-password'}
          password={confirmPassword}
          setPassword={setConfirmPassword}
          hasError={confirmPasswordErrored}
        />
        <PasswordError errorKey={invalidReasonKey} />
        <ButtonWithLoader
          isFetching={isFetching}
          type={'submit'}
          disabled={disableCreateButton}
          className={classNames('btn btn--sm btn--primary h-52', {
            disabled: disableCreateButton,
          })}
        >
          {t('create-account')}
        </ButtonWithLoader>
        <CreatePasswordBottomDescription />
      </form>
      <LoginError errKey={loginError} />
    </Modal>
  );
};

export default CreatePasswordModal;
