import {memo, useEffect, lazy, useState} from 'react';
import {useI18n} from '../../../hooks/use-i18n';
import {CustomerModelSwitcher} from './CustomerModelSwitcher';
import {di} from '../../../dependency-injector';
import {RenderWithSuspense} from '../../../components/RenderWithSuspense';
import HowDoesItWork from './HowDoesItWork';
import {useIsMobile} from '../../../redux/hooks/use-is-mobile';
import {useParams} from 'react-router';
import {CreateAccountModalHandler, LoginModalHandler} from '../../login';
import DirectLogin, {DirectLoginHandler} from '../../login/components/DirectLogin';

// Lazy load components
const WhyAnonFunBetter = lazy(() => import(/* webpackPrefetch: true */ './WhyAnonFunBetter'));
const SendPhotosBelowChin = lazy(() => import(/* webpackPrefetch: true */ './SendPhotosBelowChin'));
const MainFAQ = lazy(() => import(/* webpackPrefetch: true */ './MainFAQ'));
const MainFooter = lazy(() => import(/* webpackPrefetch: true */ './MainFooter'));
const BackToTopButton = lazy(() => import(/* webpackPrefetch: true */ '../../../components/BackToTopButton'));

interface Props {
  showLoginModalOnMount: boolean;
}

export const Main = memo((props: Props) => {
  const {showLoginModalOnMount} = props;
  const {t, T} = useI18n();
  const isMobile = useIsMobile();
  const [isCustomer, setIsCustomer] = useState(true);
  const {verifier, token, method} = useParams();

  useEffect(() => {
    // Show login modal
    if (showLoginModalOnMount) {
      LoginModalHandler.show();
    }
  }, [showLoginModalOnMount]);

  useEffect(() => {
    if (method === 'twitter' && !!verifier && !!token) {
      // perform twitter login
      LoginModalHandler.twitterLogin?.(token, verifier);
    } else if (method === 'direct') {
      DirectLoginHandler.login();
    }
  }, [verifier, token, method]);

  useEffect(() => {
    di.trafficAnalyticsService.trackVisit();
  }, []);
  const onJoinClick = () => {
    di.trafficAnalyticsService.trackClickRegister();
    CreateAccountModalHandler.show();
  };
  return (
    <>
      <DirectLogin />
      <div className="overflow-hidden">
        <div className="container grid grid-cols-1 justify-items-center text-center lg:grid-cols-2 lg:text-left">
          <div className={'grid auto-rows-auto lg:w-full'}>
            <h2 className="pt-40 text-[2.4rem] font-bold sm:text-3xl whitespace-normal lg:text-4xl">
              <T
                keyName={isCustomer ? 'main-page-headline-customer' : 'main-page-headline-model'}
                params={{br: <br className={'block lg:hidden'} />}}
              />
            </h2>
            <div className="mb-20 mt-15 text-sm text-gray-dark sm:text-base lg:mb-52 lg:mt-28 lg:text-lg">
              <T
                keyName={isCustomer ? 'anonymous-and-safe-customer' : 'anonymous-and-safe-model'}
                params={{br: <br />}}
              />
            </div>
            <CustomerModelSwitcher setIsCustomer={setIsCustomer} isCustomer={isCustomer} className={'lg:order-first'} />
            {isMobile && (
              <img
                src={
                  isCustomer
                    ? `${process.env.PUBLIC_URL}/main-ill-customer.webp`
                    : require('../../../assets/img/main-ill-model.webp')
                }
                alt={'main-logo-mobile'}
                className={'mx-auto my-26 lg:hidden'}
                width={350}
                height={280}
              />
            )}
            <button className="btn btn--md btn--primary mx-auto min-w-100 lg:mx-0 lg:w-175" onClick={onJoinClick}>
              {t('join')}
            </button>
          </div>
          {!isMobile && (
            <img
              src={
                isCustomer
                  ? `${process.env.PUBLIC_URL}/main-ill-customer.webp`
                  : require('../../../assets/img/main-ill-model.webp')
              }
              alt={'main-logo-desktop'}
              className={'hidden h-full w-full object-contain lg:block'}
            />
          )}
        </div>
      </div>
      <HowDoesItWork isCustomer={isCustomer} />
      <RenderWithSuspense>
        <SendPhotosBelowChin isCustomer={isCustomer} />
      </RenderWithSuspense>
      <RenderWithSuspense>
        <WhyAnonFunBetter isCustomer={isCustomer} />
      </RenderWithSuspense>
      <RenderWithSuspense>
        <MainFAQ isCustomer={isCustomer} />
      </RenderWithSuspense>
      <RenderWithSuspense>
        <MainFooter isCustomer={isCustomer} showLoginModal={onJoinClick} />
      </RenderWithSuspense>
      <RenderWithSuspense>
        <BackToTopButton />
      </RenderWithSuspense>
    </>
  );
});
