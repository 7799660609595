import {useI18n} from '../../../../hooks/use-i18n';
import {FormEvent, useEffect, useState} from 'react';
import {Modal, ModalProps} from '../../../../ui/Modal';
import CloseModalButton from '../../../../components/CloseModalButton';
import SubmitButton from '../SubmitButton';
import EmailInput from '../EmailInput';
import {api} from '../../../../http/api';
import {useNavigate} from 'react-router-dom';
import ResetPasswordSessionStorage from '../../../../storages/reset-password-session-storage';

const ResetPasswordModal = (props: ModalProps) => {
  const {isOpen, hide} = props;
  const {t} = useI18n();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isEmailIncorrect, setIsEmailIncorrect] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setEmail('');
    }
  }, [isOpen]);

  const onResetPasswordClick = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await api.post('api/v1/auth/password-reset/request', {
        email,
      });
      hide();
      // Save email to session storage
      ResetPasswordSessionStorage.set(email);
      navigate('/reset-password/request');
    } catch (e) {
      alert(e);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      hide={hide}
      backdropClassName={'p-0 lg:p-20'}
      className={'w-full rounded px-20 py-44 lg:h-auto lg:w-500 lg:p-54'}
    >
      <CloseModalButton onClick={hide} />
      <div className="mb-24 text-center text-[2.6rem] font-bold sm:text-2xl">{t('reset-password')}</div>
      <p className={'mb-32 text-center text-base text-black/60'}>{t('reset-password-description')}</p>
      <form className={'flex flex-col'} onSubmit={onResetPasswordClick}>
        <EmailInput
          email={email}
          setEmail={setEmail}
          setEmailIncorrect={setIsEmailIncorrect}
          checkEmailExistence={false}
        />
        <SubmitButton titleKey={'reset-password'} disabled={isEmailIncorrect} />
      </form>
    </Modal>
  );
};

export default ResetPasswordModal;
